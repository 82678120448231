import { mapActions, mapGetters } from 'vuex';
import LogoCompany from './LogoCompany/index.vue';
import Map from './Map/index.vue';
import i18n from './i18n';
import { get } from '../../../helpers/cookie';

const isValidUrl = (urlString) => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' // validate protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
      + '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  const urlPatternV2 = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  const noSpaceStr = urlString.replace(/\s/g, '');
  return !!urlPattern.test(noSpaceStr)
      || urlPatternV2.test(noSpaceStr)
      || noSpaceStr.includes('www')
      || noSpaceStr.includes('google')
      || noSpaceStr.includes('.com');
};

export default {
  name: 'EditCompany',
  components: {
    'logo-company': LogoCompany,
    'google-map': Map,
  },
  data() {
    return {
      showMap: false,
      address: '',
      region: '',
      city: '',
      geo: {
        lat: 0,
        lng: 0,
      },
      miniSiteUrl: '',
      siteUrl: '',
      name: '',
      schedule: [],
      description: '',
      logo: '',
      labels: [],
      file: null,
      disabledBtn: false,
      error: false,
      success: false,
      addressIsUrl: false,
    };
  },
  computed: {
    ...mapGetters({
      citiesInState_: 'shared/locations/citiesInState',
      regions_: 'shared/locations/zonesPlain',
      packagesData_: 'cabinet/userInfo/packagesData',
      expiredAutoDealer: 'cabinet/getExpiredAutoDealer',
    }),
    regions() {
      return this.regions_ || [];
    },
    cities() {
      return this.citiesInState_(this.region) || [];
    },
    fullAddress() {
      const city = this.cities.find(({ value }) => value === this.city);
      const region = this.regions.find(({ value }) => value === this.region);
      return `${this._result(region, 'name', '')} область ${this._result(city, 'name', '')} ${this.address}`;
    },
    companyId() {
      return this._result(this.packagesData_, 'data.company.id', 0);
    },
  },
  methods: {
    ...mapActions({
      getCitiesInState_: 'shared/locations/citiesInState',
      fetchZones_: 'shared/locations/fetchZones',
      fetchCompanyById_: 'cabinet/company/companyById',
      updateCompanyInfo_: 'cabinet/company/updateCompanyInfo',
      uploadImage_: 'others/uploadImage',
      setCompanyLogo_: 'cabinet/company/setCompanyLogo',
    }),
    getOnlyHost(str) {
      return str.replace(/(^https?:\/\/)?(.+)(\.ria\.biz$)+?/, '$2');
    },
    saveInfo() {
      this.disabledBtn = true;
      const formData = {
        name: this.name,
        site_url: this.siteUrl,
        minisite_url: this.miniSiteUrl ? `https://${this.getOnlyHost(this.miniSiteUrl)}.ria.biz` : '',
        state_id: this.region,
        city_id: this.city,
        address: this.address,
        // eslint-disable-next-line max-len
        labels: this.labels.map(({ labelId = 0, active = 0 }) => ({ id: labelId, value: active })),
        schedule: this.schedule,
        description: this.description,
        geo_X: this.geo.lat,
        geo_Y: this.geo.lng,
      };

      this.updateCompanyInfo_({ data: formData, id: this.companyId })
        .then(() => {
          if (this.file) {
            const logoFormData = new FormData();
            logoFormData.append('logo', this.file);
            logoFormData.append('companyId', this.companyId);

            this.setCompanyLogo_(logoFormData)
              .then(() => {
                this.disabledBtn = false;
                this.showSuccessMessage();
              })
              .catch((e) => {
                this.error = true;
                this.disabledBtn = false;
                console.error('ERROR:::setCompanyLogo_', e.message);
              });
          } else {
            this.showSuccessMessage();
            this.disabledBtn = false;
          }
        }).catch((e) => {
          this.error = true;
          console.error('ERROR:::updateCompanyInfo_', e.message);
        });
    },
    setLogo(file) {
      this.file = file;
    },
    showSuccessMessage() {
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 3000);
    },
    getMapCoords(coords) {
      this.geo = {
        lat: coords.lat,
        lng: coords.lng,
      };
    },
    createScheduleObject(dayFrom, dayTo, numberDayFrom, numberDayTo, weekend) {
      return {
        timeFrom: '00:00',
        timeTo: '00:00',
        active: 0,
        dayFrom,
        dayTo,
        numberDayFrom,
        numberDayTo,
        weekend,
      };
    },
  },
  mounted() {
    this.fetchZones_();
    this.fetchCompanyById_({ id: this.companyId, withoutCache: Date.now() }).then((info = {}) => {
      const {
        location: {
          cityId = 0, stateId = 0, address = '', geo: { X = 0, Y = 0 } = {},
        } = {},
        logo = '',
        name: { original = '' } = {},
        minisiteUrl = '',
        siteUrl = '',
        description = '',
        schedule = [],
        labels = [],
      } = info;
      this.region = stateId || '';
      this.city = cityId || '';
      this.logo = logo;
      this.address = address;
      this.name = original;
      this.miniSiteUrl = this.getOnlyHost(minisiteUrl);
      this.siteUrl = siteUrl;
      this.labels = labels;
      // @todo тимчасово
      this.description = description.replace(/<.?script>/g, '');
      this.geo = {
        lat: Number(X),
        lng: Number(Y),
      };
      this.schedule = schedule;
    });
  },
  watch: {
    region(newValue) {
      this.getCitiesInState_({ id: newValue });
      if (!newValue) {
        this.city = '';
      }
    },
    cities(newValue, oldValue) {
      const haveCity = newValue.find(({ value }) => this.city === value);
      if (!haveCity && oldValue.length) {
        this.city = '';
      }
    },
    expiredAutoDealer: {
      handler({ status }) {
        if (status) {
          this.$router.push({ name: 'CabinetMain' });
        }
      },
      immediate: true,
    },
    address(newValue) {
      if (typeof newValue !== 'string') return;
      this.addressIsUrl = isValidUrl(newValue);
    },
    addressIsUrl(newValue) {
      this.disabledBtn = newValue;
    },
  },
  created() {
    if (!this.companyId) {
      this.$router.push({ name: 'CabinetMain' });
    }
  },
  i18n,
};
