export default {
  messages: {
    ru: {
      'Інформація про компанію': 'Информация о компании',
      'Змінити фото': 'Изменить фото',
      Компанія: 'Компания',
      'Введіть назву компанії': 'Введите название компании',
      'Введіть сайт': 'Введите сайт',
      Мінісайт: 'Минисайт',
      'Введіть назву': 'Введите название',
      Область: 'Область',
      'Введіть область': 'Введите область',
      Місто: 'Город',
      'Введіть місто': 'Введите город',
      Адреса: 'Адрес',
      'Введіть адресу компанії': 'Введите адрес компании',
      'Вказати на карті': 'Указать на карте',
      'Приховати карту': 'Скрыть карту',
      'Про компанію': 'О компании',
      'Напишіть короткий опис компанії': 'Напишите краткое описание компании',
      'Додаткові послуги': 'Дополнительные услуги',
      'На сторінці публікації': 'На странице публикации',
      'Обрані послуги будуть відображатися на вашій сторінці автодилера та на деяких обраних сторінках': 'Выбранные услуги будут отображаться на вашей странице автодилера и на некоторых выбранных страницах',
      'Обрана послуга буде додатково відображатися на всіх сторінках ваших публікацій': 'Выбранная услуга будет дополнительно отображаться на всех страницах ваших публикаций',
      'На сторінці автодилера': 'На странице автодилера',
      'Обрані послуги будуть відображатися на вашій сторінці автодилера': 'Избранные услуги будут отображаться на странице автодилера',
      'Години роботи': 'Время работы',
      'Понеділок - п’ятниця': 'Понедельник - пятница',
      Вихідний: 'Выходной',
      Неділя: 'Воскресенье',
      'Зберегти зміни': 'Сохранить изменения',
      з: 'с',
      Субота: 'Суббота',
      'Більше 10 Мб': 'Больше 10 Мб',
      'Некоректні дані, повторіть спробу': 'Некоректные данные, повторите попытку',
      'Дані успішно збережені': 'Данные успешно сохранены',
      'Ваша адреса являється посиланням, спробуйте виправити для збереження даних': 'Ваш адрес является ссылкой, попробуйте исправить для сохранения даных',
    },
    uk: {
      'Інформація про компанію': 'Інформація про компанію',
      'Змінити фото': 'Змінити фото',
      Компанія: 'Компанія',
      'Введіть назву компанії': 'Введіть назву компанії',
      'Введіть сайт': 'Введіть сайт',
      Мінісайт: 'Мінісайт',
      'Введіть назву': 'Введіть назву',
      Область: 'Область',
      'Введіть область': 'Введіть область',
      Місто: 'Місто',
      'Введіть місто': 'Введіть місто',
      Адреса: 'Адреса',
      'Введіть адресу компанії': 'Введіть адресу компанії',
      'Вказати на карті': 'Вказати на карті',
      'Приховати карту': 'Приховати карту',
      'Про компанію': 'Про компанію',
      'Напишіть короткий опис компанії': 'Напишіть короткий опис компанії',
      'Додаткові послуги': 'Додаткові послуги',
      'На сторінці публікації': 'На сторінці публікації',
      'Обрані послуги будуть відображатися на вашій сторінці автодилера та на деяких обраних сторінках': 'Обрані послуги будуть відображатися на вашій сторінці автодилера та на деяких обраних сторінках',
      'Обрані послуги будуть відображатися на всіх сторінках ваших публкацій': 'Обрані послуги будуть відображатися на всіх сторінках ваших публкацій',
      'Обрана послуга буде додатково відображатися на всіх сторінках ваших публікацій': 'Обрана послуга буде додатково відображатися на всіх сторінках ваших публікацій',
      'На сторінці автодилера': 'На сторінці автодилера',
      'Обрані послуги будуть відображатися на вашій сторінці автодилера': 'Обрані послуги будуть відображатися на вашій сторінці автодилера',
      'Години роботи': 'Години роботи',
      'Понеділок - п’ятниця': 'Понеділок - п’ятниця',
      Вихідний: 'Вихідний',
      Неділя: 'Неділя',
      'Зберегти зміни': 'Зберегти зміни',
      з: 'з',
      Субота: 'Субота',
      'Більше 10 Мб': 'Більше 10 Мб',
      'Некоректні дані, повторіть спробу': 'Некоректні дані, повторіть спробу',
      'Дані успішно збережені': 'Дані успішно збережені',
      'Ваша адреса являється посиланням, спробуйте виправити для збереження даних': 'Ваша адреса являється посиланням, спробуйте виправити для збереження даних',
    },
  },
};
