export default {
	name: 'LogoCompany',
	props: {
		logo: {
			type: String,
			default() {
				return '';
			}
		}
	},
	data() {
		return {
			image: null,
			file: null,
			error: '',
		};
	},
	computed: {
		src() {
			const defaultLogo = this.logo ? `https://cdn.riastatic.com/photos/${this.logo.replace('.jpg', 'q.jpg')}?v=${Math.random()}` : 'https://cdn.riastatic.com/docs/pictures/common/1/135/13592/13592.svg';
			return this.image ? this.image : defaultLogo;
		},
	},
	methods: {
		onFileChange(e) {
			this.error = '';
			// видаляємо з пам'яті попередній файл
			if (this.image) {
				URL.revokeObjectURL(this.image);
			}
			const file = e.target.files[0];
			const fileSizeMB = this._result(file, 'size', 0) / 1024 / 1024;
			if (Number(fileSizeMB) > 10) {
				this.error = this.$t('Більше 10 Мб');
			} else {
				this.image = URL.createObjectURL(file);
				this.$emit('setLogo', file);
			}
		},
	},
	i18n: require('../i18n').default,
};
