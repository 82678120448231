export default {
	name: 'EditCompanyMap',
	components: {
		'google-map': require('../../../Common/GoogleMap/index.vue').default,
	},
	props: {
		address: {
			type: String,
		},
		center: {
			type: Object,
		}
	},
	data() {
		return {
			mapStatus: false,
			googleMapConfig: {
				zoom: 18,
			},
			geo: null,
			floatMarkerSettings: {
				enable: true,
				dynamicCoordinates: true,
			}
		};
	},
	methods: {
		setMapCenterByAddress(name) {
			this.$refs.googleMap.setCenterByAddress(name)
				.then(geo => {
					this.geo = {
						X: geo.lat,
						Y: geo.lng
					};
					this.$emit('mapCoords', geo);
				})
				.catch(e => console.error('ERROR:::this.$refs.googleMap.setCenterByAddress', e.message));
		},
		setMapStatus(status) {
			this.mapStatus = status;
		},
		coordsDraggableMarker(coordinates) {
			this.geo = {
				X: coordinates.lat,
				Y: coordinates.lng
			};
			this.$emit('mapCoords', coordinates);
		}
	},
	watch: {
		address(newValue) {
			if (newValue && this.mapStatus) {
				this.setMapCenterByAddress(newValue);
			}
		},
	}
};
